<template>
  <div>
    <div id="statementStepperContainer">
      <h3 id="statementStepperContainerTitle">
        Finalize {{monthAndYear}} Commissions
      </h3>

      <div v-if="!loading.steps">
        <v-stepper class="custom-header stepperComponent" height="48" v-model="activeStep">
          <v-stepper-header>
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :complete="steps[index].complete"
                :step="index + 1"
                class="stepperStep"
                :editable="step.canEdit"
                edit-icon="$complete"
              >
                <div class="d-flex align-center">
                  <span class="stepNumber">0{{ index + 1 }}</span>
                  <div class="d-flex flex-column">
                    <span class="stepLabel">{{ step.display }}</span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider v-if="index !== steps.length - 1"></v-divider>
            </template>
          </v-stepper-header>
        </v-stepper>
      </div>
    </div>

    <div id="statementContentContainer" v-if="!loading.steps">
      <router-view
        :key="$route.fullPath"
        @goToStep="goToStep"
        @goToStepByName='goToStepByName'
        @nextStep="goToNextStep"
        @previousStep='goToPreviousStep'
        :activeStep="activeStep"
        @forceActiveStep="forceActiveStep"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  data() {
    return {
      activeStep: 1,

      stepRoutes: {
        aggregator: 'commission-statement.aggregator',
        unmatched: 'commission-statement.unmatched',
        producers: 'commission-statement.producers',
        producer: 'commission-statement.producer',
        finalize: 'commission-statement.finalize',
        entry: 'commission-statement.entry',
      },

      loading: {
        steps: true,
      },

      disableWatcher: false,
    }
  },
  methods: {
    ...mapActions({
      loadCommissionSetup: 'commissions/loadCommissionSetup',
      loadProducers: 'commissions/producers/loadProducers',
      loadStepsStatuses: 'commissions/monthlyReview/loadStepsStatuses',
      refreshStepsStatuses: 'commissions/monthlyReview/refreshStepsStatuses',
    }),
    goToStep(stepNumber) {
      this.refreshStepsStatuses().then(() => {
        let canAccess = this.checkStepPermission(stepNumber)

        if (canAccess) {
          this.activeStep = stepNumber
        } else {
          this.goToFurthestStep()
        }
      })
    },
    goToStepByName(stepName) {
      let index = this.getStepIndex(stepName)
      this.goToStep(index + 1)
    },
    forceActiveStep(stepName) {
      let index = this.getStepIndex(stepName)
      this.disableWatcher = true
      this.activeStep = index + 1
      this.disableWatcher = false
    },
    goToFurthestStep() {
      this.activeStep = this.currentStep
    },
    getStepIndex(stepName) {
      if (stepName === 'producer') {
        stepName = 'producers'
        this.$debug.info('getStepIndex producer, change to producers')
      }

      let matchedIndex = this.steps.findIndex(step => step.id === stepName)

      this.$debug.info('getStepIndex', {
        requestedStepName: stepName,
        matchedIndex: matchedIndex,
      })

      return matchedIndex
    },
    getStepFromIndex(index) {
      return this.steps[index]
    },
    goToNextStep() {
      this.goToStep(this.activeStep + 1)
    },
    goToPreviousStep() {
      this.$debug.info('going to previous step');
      this.goToStep(this.activeStep - 1)
    },
    checkStepPermission(stepNumber) {
      // if already finalized, don't let them in any step
      if (this.isFinalized && this.$route.name !== 'commission-statement.finalize') {
        this.$debug.info('already finalized')
        return false
      }

      let step = this.getStepFromIndex(stepNumber - 1)

      this.$debug.info('analyzing permissions for step', {
        step: step,
        stepNumber: stepNumber,
      })

      if (step === undefined) {
        return false
      }

      if (step && step.canEdit === false) {
        return false
      }

      return true
    },
  },
  watch: {
    activeStep(newStep, previousStep) {
      if (newStep === 0) {
        return
      }

      let producersIndex = this.getStepIndex('producers')

      if (this.$route.name === 'commission-statement.producer' && newStep === producersIndex + 1) {
        return
      }

      if (this.disableWatcher) {
        this.$debug.info('watcher disabled, skipping active step change')
        return
      }

      this.refreshStepsStatuses().then(() => {
        this.$debug.info('step statuses refreshed, now navigating', {
          newStep: newStep,
          previousStep: previousStep,
          to: this.stepRoutes[this.steps[newStep - 1].id],
        })

        this.$router
          .push({
            name: this.stepRoutes[this.steps[newStep - 1].id],
            params: {
              year: this.$route.params.year,
              month: this.$route.params.month,
            },
          })
          .catch(() => {})
      })
    },
    date(newMonth, previousMonth) {
      if (newMonth === previousMonth) {
        return
      }

      this.loadProducers({
        date: this.date,
      })
    },
    '$route.path': function(newPath, oldPath) {
      this.$debug.info('route path changed', {
        newPath: newPath,
        oldPath: oldPath,
      })
    },
    '$route.name': function(newName, oldName) {
      this.$debug.info('route name changed', {
        newName: newName,
        oldName: oldName,
      })

      if(newName !== oldName){
        //check if active step is same as route, if not update active step number
        let requestedStepName = _.findKey(this.stepRoutes, stepRoute => {
          if (stepRoute === this.$route.name) {
            return true
          }
        })

        let requestedStepIndex = this.getStepIndex(requestedStepName)
        this.activeStep = requestedStepIndex + 1
      }
    },
  },
  mounted() {
    this.loadCommissionSetup().then(() => {
      this.loadStepsStatuses({ date: this.date }).then(() => {
        this.loading.steps = false

        let requestedStepName = _.findKey(this.stepRoutes, stepRoute => {
          if (stepRoute === this.$route.name) {
            return true
          }
        })

        this.$debug.info('requested step name', requestedStepName)

        if (requestedStepName) {
          if (requestedStepName === 'producer') {
            this.$debug.info('disabling watcher')
            this.disableWatcher = true
            this.activeStep = this.getStepIndex('producers') + 1
            this.$debug.info('active step', this.activeStep)
            this.$debug.info('enabling watcher')
            this.disableWatcher = false
            return
          }

          this.$debug.info('moved past producer step check')

          let requestedStepIndex = this.getStepIndex(requestedStepName)
          this.goToStep(requestedStepIndex + 1)
        } else {
          this.$router
            .push({
              name: this.stepRoutes['entry'],
              params: {
                year: this.$route.params.year,
                month: this.$route.params.month,
              },
            })
            .catch(() => {})
        }
      })
    })
  },
  computed: {
    ...mapGetters({
      stepStatus: 'commissions/monthlyReview/getStepStatus',
      currentStep: 'commissions/monthlyReview/getCurrentStep',
      currentUser: 'getCurrentUser',
      isFinalized: 'commissions/monthlyReview/getFinalized',
      commissionSetup: 'commissions/getCommissionSetup',
    }),
    monthAndYear(){
      //return name of month and the 4 digit year
      let month = this.$route.params.month
      let year = this.$route.params.year

      let monthName = this.$moment().month(month - 1).format('MMMM')

      return `${monthName} ${year}`
    },
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
    steps() {
      //first we need to see if aggregator enabled
      let aggregatorEnabled = this.currentUser?.related?.agency?.data?.attributes?.has_aggregator

      let steps = []

      if (aggregatorEnabled) {
        steps.push({
          weight: 0,
          id: 'aggregator',
          display: 'Update Aggregator Commission',
          complete: this.stepStatus['aggregator'],
          canEdit: true && !this.isFinalized,
        })
      }

      steps.push({
        weight: 1,
        id: 'unmatched',
        display: 'Review Unassigned Commissions',
        complete: this.stepStatus['unmatched'],
        canEdit: !this.isFinalized,
      })

      steps.push({
        weight: 2,
        id: 'producers',
        display: 'Approve Producer Pay',
        complete: this.stepStatus['producers'],
        // canEdit: this.stepStatus['unmatched'] === true && !this.isFinalized,
        canEdit: !this.isFinalized,
      })

      steps.push({
        weight: 3,
        id: 'finalize',
        display: 'Finalize Report',
        complete: this.stepStatus['finalize'],
        canEdit: this.stepStatus['producers'] === true && !this.isFinalized,
      })

      return steps
    },
  },
}
</script>

<style scoped>
#statementStepperContainer,
#statementContentContainer {
  margin-bottom: 8px;
}

#statementStepperContainer {
  background: white;
  padding: 24px 32px 16px 32px;
}

#statementContentContainer {
  padding-bottom: 16px;
}

::v-deep #statementStepperContainerTitle {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
}

.custom-header {
  box-shadow: none !important;
}

#statementStepperContainerTitle,
::v-deep #statementContentContainerTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #3a3541;
}

#statementStepperContainer .stepNumber,
::v-deep #statementContentContainerTitle .stepNumber {
  color: #3a3541;
  font-size: 20px;
  font-weight: 600;
  line-height: 42px;
  margin-right: 10px;
  font-family: 'Inter', sans-serif;
  letter-spacing: 0.25px;
}

#statementStepperContainer .stepLabel {
  color: #3a3541;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
}

::v-deep #statementStepperContainer .custom-header .v-stepper__step__step::after {
  transform: scale(0.7) !important;
}

.stepperStep {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

::v-deep #statementStepperContainer .v-divider {
  margin: 0 8px;
}

::v-deep #statementStepperContainer .v-ripple__container {
  display: none !important;
}

.stepperStep:focus,
.stepperStep:hover {
  background: none !important;
}

.v-divider {
  top: -12px;
}
</style>
